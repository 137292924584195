import layout from "./src/assets/generated/themes/velopark.json"
import { ThemeMetaTagging } from "./src/assets/generated/metatagging/velopark"
import selected_element from "./src/assets/generated/layers/selected_element.json"
import gps_location from "./src/assets/generated/layers/gps_location.json"
import gps_location_history from "./src/assets/generated/layers/gps_location_history.json"
import home_location from "./src/assets/generated/layers/home_location.json"
import gps_track from "./src/assets/generated/layers/gps_track.json"
import range from "./src/assets/generated/layers/range.json"
import last_click from "./src/assets/generated/layers/last_click.json"
import favourite from "./src/assets/generated/layers/favourite.json"
import summary from "./src/assets/generated/layers/summary.json"
import ThemeViewState from "./src/Models/ThemeViewState"
import ThemeViewGUI from "./src/UI/ThemeViewGUI.svelte"
import LayoutConfig from "./src/Models/ThemeConfig/LayoutConfig";
import MetaTagging from "./src/Logic/MetaTagging";
import { FixedUiElement } from "./src/UI/Base/FixedUiElement";
import { Utils } from "./src/Utils"
import Constants from "./src/Models/Constants"

function webgl_support() {
    try {
        var canvas = document.createElement("canvas")
        return (
            !!window.WebGLRenderingContext &&
            (canvas.getContext("webgl") || canvas.getContext("experimental-webgl"))
        )
    } catch (e) {
        return false
    }
}

async function timeout(timeMS: number): Promise<{ layers: string[] }> {
    await Utils.waitFor(timeMS)
    return { layers: [] }
}


async function getAvailableLayers(): Promise<Set<string>> {
    try {
        const host = new URL(Constants.VectorTileServer).host
        const status = await Promise.any([
                                   Utils.downloadJson("https://" + host + "/summary/status.json"),
                                   timeout(0)
                               ])
        return new Set<string>(status.layers)
    } catch (e) {
        console.error("Could not get MVT available layers due to", e)
        return new Set<string>()
    }
}

async function main() {
    if (!webgl_support()) {
         new FixedUiElement("WebGL is not supported or not enabled. This is essential for MapComplete to function, please enable this.").SetClass("block alert").AttachTo("maindiv")
    }else{
        const availableLayers = await getAvailableLayers()
        MetaTagging.setThemeMetatagging(new ThemeMetaTagging())
        layout.layers.push(<any> selected_element)
    layout.layers.push(<any> gps_location)
    layout.layers.push(<any> gps_location_history)
    layout.layers.push(<any> home_location)
    layout.layers.push(<any> gps_track)
    layout.layers.push(<any> range)
    layout.layers.push(<any> last_click)
    layout.layers.push(<any> favourite)
    layout.layers.push(<any> summary)
        layout.enableMoreQuests = false
        const state = new ThemeViewState(new LayoutConfig(<any> layout), availableLayers)
        const target =  document.getElementById("maindiv")
        const childs = Array.from(target.children)
        new ThemeViewGUI({
            target,
            props: { state },
        })
        childs.forEach(ch => target.removeChild(ch))
        Array.from(document.getElementsByClassName("delete-on-load")).forEach(el => {
                    el.parentElement.removeChild(el)
                })
    }
}
main()
